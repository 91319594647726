
import {Component, Vue} from 'vue-property-decorator';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import MenuTop from '@/components/MenuTop.vue';
import AppStore from '@/components/AppStore';
import FinesTable from '@/components/fines/FinesTable.vue';

@Component({
  components: {FinesTable, MenuTop, AppTopBar}
})
export default class FinesView extends Vue {

  containerWidth = AppStore.containerWidth;

  mounted() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
    AppStore.setDisplayModeTable();
  }

}
