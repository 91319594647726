
import {Component, Vue} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import Http from '@/Http';
import FinesExportExcel from '@/components/fines/FinesExportExcel.vue';

@Component({
  components: {FinesExportExcel}
})
export default class FinesTable extends Vue {

  loading = true;
  items: any[] = [];

  regNumbers = AppStore.regNumbers;

  filterData = {
    status: 'notPaid',
    regNumber: '',
    query: '',
    order: '',
    sort: 'DESC',
    offset: '0',
    limit: '50',
  };

  getStatusColor(item: any) {
    if (!item.doneDate) {
      return 'grey';
    }
    return 'green';
  }

  isActiveDiscount(item: any): boolean {
    const d = this.$moment(item.discountDate as string).startOf('day');
    const current = this.$moment().startOf('day');
    const days = this.$moment(d).diff(current, 'days');
    return days >= 0;
  }

  getStatusText(item: any) {
    if (!item.doneDate) {
      return 'Не оплачен';
    }
    return 'Оплачен';
  }

  load() {
    AppStore.showLoader();
    this.loading = true;
    Http.post('/fines', this.filterData).then((response: any) => {
      this.items = response;
    }).finally(() => {
      this.loading = false;
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

  init() {
    this.load();
  }

  mounted() {
    this.init();

  }

}
