var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":"Рег. номер","items":_vm.regNumbers,"hide-details":"","outlined":"","prepend-inner-icon":_vm.filterData.regNumber ? 'mdi-close' : 'mdi-credit-card-chip-outline'},on:{"change":_vm.load,"click:prepend-inner":function($event){_vm.filterData.regNumber = ''; _vm.load()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-1"},[_vm._v("Рег. номер")])]},proxy:true}]),model:{value:(_vm.filterData.regNumber),callback:function ($$v) {_vm.$set(_vm.filterData, "regNumber", $$v)},expression:"filterData.regNumber"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Статус оплаты","items":[
                     {text: '', value: null},
                     {text: 'Не оплачен', value: 'notPaid'},
                     {text: 'Оплачен', value: 'paid'}
                     ],"hide-details":"","outlined":""},on:{"change":_vm.load},model:{value:(_vm.filterData.status),callback:function ($$v) {_vm.$set(_vm.filterData, "status", $$v)},expression:"filterData.status"}})],1),_c('v-col',{attrs:{"align-self":"center","align":"right"}},[_c('fines-export-excel')],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',{},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":[
  {text: 'Дата постановления', value: 'resolutionDate', width:'180px'},
  {text: 'Дата нарушения', value: 'offenceAt', width:'180px'},
  {text: 'Рег. номер', value: 'regNumber', width:'130px'},
  {text: '',  sortable: false, value: 'resolutionNumber'},
  {text: 'Статус',  value: 'status', width:'150px'},
  {text: 'Сумма', width:'230px'}
    ],"items":_vm.items,"disable-pagination":"","disable-filtering":"","hide-default-footer":"","must-sort":true},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-10 text-body-1"},[_vm._v(" Данные по штрафам отсутсвуют ")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.resolutionDate,"DD.MM.YYYY"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.offenceAt,"DD.MM.YYYY HH:mm:ss"))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.regNumber)+" ")]),_c('td',{staticClass:"py-2"},[(item.lawDescription)?_c('div',{staticClass:"pt-1"},[_c('strong',[_vm._v(_vm._s(item.law)+" "+_vm._s(item.lawDescription))])]):_vm._e(),_c('div',{staticClass:"pt-1"},[_vm._v("УИН: "+_vm._s(item.resolutionNumber))]),_c('div',{staticClass:"pt-1"},[_vm._v("СТС: "),_c('strong',[_vm._v(_vm._s(item.stsNumber))])]),(item.location)?_c('div',{staticClass:"pt-1"},[_vm._v("место: "),_c('br'),_vm._v(" "),_c('strong',[_vm._v(_vm._s(item.location))])]):_vm._e()]),_c('td',[_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getStatusColor(item)}},[_vm._v(_vm._s(_vm.getStatusText(item)))])],1),_c('td',[(_vm.isActiveDiscount(item))?_c('div',[_c('del',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm._f("currency")(item.totalAmount))+" ₽")]),_vm._v("   "),_c('span',{staticClass:"text-subtitle-1"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalAmount * (item.discountSize / 100)))+" ₽ ")]),_c('br'),_c('small',[_vm._v("скидка действует до "+_vm._s(_vm._f("dateFormat")(item.discountDate,"DD.MM.YYYY")))])])]):_c('div',{staticClass:"text-subtitle-1"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalAmount))+" ₽ ")])])])])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }